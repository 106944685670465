<template>
  <div class="card" style="width: 24rem;">
    <div class="card-header">
      Tasks
    </div>
    <TaskCard v-for="task in tasks" :key="task.id" :task="task"/>

    <div class="pagination">
      <!-- Adding previous and next page links -->
      <router-link
        id="prev"
        :to="{ name: 'TasksList', query: { page: page - 1 }}"
        v-if="page != 1"> &#60; Prev
      </router-link>

      <router-link
        id="next"
        :to="{ name: 'TasksList', query: { page: page + 1 }}"
        v-if="hasNextPage"> Next &#62;
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TaskCard from '@/components/TaskCard.vue'
// import axios from 'axios'
import TasksService from '@/services/TasksService.js'
import { watchEffect } from 'vue'

export default {
  name: 'TasksList',
  props: ['page'],
  components: {
    TaskCard
  },
  data () {
    return {
      tasks: null,
      totalTasks: 0
      /* [
        {
          id: 1,
          title: 'Task 1',
          description: 'Description for task 1',
          location: 'Valletta',
          date: '1st of Jan',
          time: '12:00'
        },
        {
          id: 2,
          title: 'Task 2',
          description: 'Description for task 2',
          location: 'Valletta',
          date: '1st of Jan',
          time: '12:00'
        },
        {
          id: 3,
          title: 'Task 3',
          description: 'Description for task 3',
          location: 'Valletta',
          date: '1st of Jan',
          time: '12:00'
        }
      ] */
    }
  },

  created () {
    // axios.get('https://my-json-server.typicode.com/RitianaEllul-MSD62A/MyJSONServer/tasks')
    watchEffect(() => {
      console.log('Page is ' + this.page)
      TasksService.getTasks(2, this.page)
        .then(response => {
          // console.log('tasks: ', response.data)
          this.tasks = response.data
          this.totalTasks = response.headers['x-total-count']
        })
        .catch(error => {
          console.log('ERRORS:' + error)
        })
    })
  },

  computed: {
    hasNextPage () {
      // Math.ceil - round up the value to the nearest larger number
      // Divide by 2 since 2 tasks per page are being shown
      var totalPages = Math.ceil(this.totalTasks / 2)
      // If this page is not the last page
      return this.page < totalPages
    }
  }
}
</script>

<style scoped>

.card {
  margin: auto;
}

.pagination {
  display: flex;
  width: 382px;
}

.pagination a{
  flex: 1;
  text-decoration: none;
  color: black;
}

#prev{
  text-align: left;
}

#next{
  text-align: right;
}

</style>
